.svgGridEmissions {
  width: 280px;
  height: 210px;
}
.svgGridEmissions:not(:root) {
  overflow: hidden;
}
.block-page-title.template-green_index .guage #gauge_container {
  margin-top: 20px;
}
#gauge {
  position: absolute;
  top: 0 !important;
  left: 0;
  width: 100%;
}
.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
}
#gauge > svg.ct-chart-donut > g.ct-series > path.ct-slice-donut {
  stroke-width: 40px !important;
  stroke: #e0e7ff;
  animation: auto;
}
#gauge_ring > svg.ct-chart-donut > g.ct-series > path.ct-slice-donut {
  stroke: #fff;
  stroke-width: 8px !important;
}
.ct-series-a .ct-bar,
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
  stroke: #d70206;
}
.ct-slice-donut {
  fill: none;
  stroke-width: 60px;
}
#gauge_array > svg.ct-chart-donut > g.ct-series > path.ct-slice-donut {
  stroke-width: 40px !important;
  stroke: #fff;
  stroke-dasharray: 2px;
}
.ct-series-a .ct-bar,
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
  stroke: #d70206;
}

#gauge_container {
  display: block;
  position: relative;
  text-align: center;
  height: 190px;
  overflow: hidden;
}
#gauge_container > .emission_count_container {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 100px 0 0 0;
  color: #8798ad;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 1.2;
  font-family: 'Lato';
}
#gauge_ring {
  position: absolute;
  top: 0 !important;
  left: 0;
  width: 100%;
  padding-top: 14px;
}
#gauge_array {
  position: relative;
}
#gauge_disabled {
  position: absolute;
  top: 0 !important;
  left: 0;
  width: 100%;
}
#emission_count {
  display: block;
  color: #202020;
  font-family: 'Lato';
  margin: 0;
  font-size: 44px;
  font-weight: bold;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  /* font: inherit; */
  vertical-align: baseline;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
div {
  display: block;
}
.block-page-title.template-green_index .guage {
  display: inline-block;
  vertical-align: top;
  background-color: #fff;
  box-shadow: 0 36px 24px -16px #616b80;
  border: 1px solid #d8e1ec;
  border-radius: 8px;
  padding: 1.25rem 2rem;
  text-align: left;
  width: 354px;
  position: relative;
}
.block-page-title.template-green_index {
  padding: 0 0 60px 0;
  text-align: center;
  position: relative;
}
.block-page-title {
  color: #f2f5f7;
  background-color: #183b66;
}

#root {
  white-space: pre-line;
}
